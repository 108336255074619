import { useState, useEffect, useMemo } from 'react'
import * as dateFns from 'date-fns'
import version from '../../../utils/version.js'
import './AdminTools.scss'
import history from '../../../history.js'
import api from '../../../api/api.js'
import Button from '../../../components/Button/Button.js'
import Icon from '../../../components/Icon/Icon.js'
import Spinner from '../../../components/Spinner/Spinner.js'
import Input from '../../../components/Input/Input.js'
import DateInput from '../../../components/DateInput/DateInput'
import { createNotification } from '../../../components/Notifier/Notifier.js'
import useHandleErrors from '../../../hooks/useHandleErrors.js'
import useAdminInfo from '../../../hooks/useAdminInfo.js'
import useExports from '../../../hooks/useExports.js'
import setAdminLoggedOut from '../../../utils/setAdminLoggedOut.js'

function getValueForDateInput (date) {
  return dateFns.format(date, 'yyyy-MM-dd')
}

const defaultAdminInfo = {}
const defaultAvailableExports = []

const AdminTools = () => {
  const [dates, setDates] = useState({
    startDate: getValueForDateInput(new Date()),
    endDate: getValueForDateInput(new Date())
  })
  const [submittedLoading, setSubmittedLoading] = useState(false)
  const [unsubmittedLoading, setUnsubmittedLoading] = useState(false)
  const [adminInfoError, , adminInfo] = useAdminInfo(defaultAdminInfo)
  const [hasLoadedAdminInfo, setHasLoadedAdminInfo] = useState(false)
  const [downloadError, setDownloadError] = useState(null)
  const [availableExportsError, availableExportsLoading, availableExports, reloadAvailableExports] = useExports(defaultAvailableExports)
  const [businessPartnerId, setBusinessPartnerId] = useState('')
  const [reopenSchoolError, setReopenSchoolError] = useState(null)

  useHandleErrors([adminInfoError, downloadError, availableExportsError, reopenSchoolError])

  useEffect(() => {
    if (adminInfo.lastExported && !hasLoadedAdminInfo) {
      const date = new Date(adminInfo.lastExported)
      if (isNaN(date)) return
      const formattedDate = getValueForDateInput(date)
      if (formattedDate !== dates.startDate) {
        setDates({
          ...dates,
          startDate: getValueForDateInput(date)
        })
      }
      setHasLoadedAdminInfo(true)
    }
  }, [adminInfo, dates, hasLoadedAdminInfo])

  function handleDateChange (name) {
    return date => {
      setDates({
        ...dates,
        [name]: getValueForDateInput(new Date(date))
      })
    }
  }

  function handleLogoutClick () {
    setAdminLoggedOut()
    return history.push('/admin/login')
  }

  async function handleDownloadSubmittedClick () {
    if (!dates.startDate || !dates.endDate) return
    setSubmittedLoading(true)
    setDownloadError(null)
    try {
      await api.exports.downloadSubmittedAccountsData({
        startDate: dates.startDate,
        endDate: dates.endDate
      })
      createNotification({
        message: 'De export is nu in behandeling. Deze zal later beschikbaar zijn in de beschikbare exports tabel.',
        type: 'success'
      })
      reloadAvailableExports()
    } catch (error) {
      console.error(error)
      setDownloadError(error)
    } finally {
      setSubmittedLoading(false)
    }
  }

  async function handleDownloadUnsubmittedClick () {
    setUnsubmittedLoading(true)
    setDownloadError(null)
    try {
      const result = await api.exports.downloadUnsubmittedAccountsData()
      const blob = await result.blob()
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'schoolteamgegevens-export.zip'
      try {
        const contentDisposition = result.headers.get('content-disposition') || ''
        const filename = contentDisposition.split(';').find(string => ~string.indexOf('filename')).trim().split('=')[1].slice(1, -1)
        if (filename) {
          link.download = filename
        }
      } catch (error) {}
      link.click()
    } catch (error) {
      console.error(error)
      setDownloadError(error)
    } finally {
      setUnsubmittedLoading(false)
    }
  }

  function handleBusinessPartnerIdChange (e) {
    setBusinessPartnerId(e.target.value)
  }

  async function handleReopenSubmit (e) {
    e.preventDefault()
    if (!businessPartnerId) return
    setReopenSchoolError(null)
    try {
      const response = await api.accounts.openSchool({ businessPartnerId: businessPartnerId })
      if (response && response.success) {
        createNotification({
          message: `De school met businesspartnerid '${businessPartnerId}' werd heropend.`,
          type: 'success'
        })
        setBusinessPartnerId('')
      }
    } catch (error) {
      setReopenSchoolError(error)
    }
  }

  return (
    <div className="AdminTools">
      <img id="logo" alt="van in logo" src="/images/logo.png" />
      <div className="AdminTools-logout">
        <div>
          <p className="AdminTools-version">{`v ${version}`}</p>
          <Button buttonType="ghost" onClick={handleLogoutClick}>
            <Icon name="logOut" width={22} height={22} />
            UITLOGGEN
          </Button>
        </div>
      </div>
      <div className="AdminTools-body">
        <div className="AdminTools-form">
          <h3>Gegevens 2020 - 2021</h3>
          <hr />
          <div className="AdminTools-input-row">
            <h5>Van</h5>
            <DateInput
              value={new Date(dates.startDate)}
              maxDate={new Date(dates.endDate)}
              onChange={handleDateChange('startDate')}
            />
            <h5>tot</h5>
            <DateInput
              value={new Date(dates.endDate)}
              minDate={new Date(dates.startDate)}
              onChange={handleDateChange('endDate')}
            />
          </div>
          <div className="AdminTools-buttons">
            <div className="AdminTools-button-container">
              <h4>Ingediende gegevens</h4>
              <Button className="AdminTools-button" onClick={handleDownloadSubmittedClick}>
                { submittedLoading ? <Spinner width={24} height={24} stroke="var(--white)" /> : <><Icon name="download" width={18} height={18} />Download</>}
              </Button>
            </div>
            <div className="AdminTools-button-container">
              <h4>Niet ingediende gegevens</h4>
              <Button className="AdminTools-button" onClick={handleDownloadUnsubmittedClick}>
                { unsubmittedLoading ? <Spinner width={24} height={24} stroke="var(--white)" /> : <><Icon name="download" width={18} height={18} />Download</>}
              </Button>
            </div>
          </div>
        </div>
        <div className="AdminTools-exports">
          <h3>Beschikbare exports <p className="AdminTools-exports-subtitle">(ingediende gegevens)</p></h3>
          <hr />
          <ExportsTable availableExports={availableExports} availableExportsLoading={availableExportsLoading} />
        </div>
        <div className="AdminTools-reopen">
          <h3>Ingediende scholen opnieuw openen</h3>
          <hr />
          <form onSubmit={handleReopenSubmit}>
            <div className="AdminTools-reopen-input">
              <Input type="text" value={businessPartnerId} onChange={handleBusinessPartnerIdChange} label="Klantnummer" />
            </div>
            <Button type="submit" className="AdminTools-button">
              { submittedLoading ? <Spinner width={24} height={24} stroke="var(--white)" /> : <>Heropen school</>}
            </Button>
          </form>
        </div>
      </div>
    </div>
  )
}

const ExportsTable = ({ availableExports, availableExportsLoading }) => {
  const sortedExports = useMemo(() => {
    return [...availableExports].sort((a, b) => {
      let num = 0
      const aStart = new Date(a.startedAt)
      const bStart = new Date(b.startedAt)
      if (aStart < bStart) num = 1
      if (aStart > bStart) num = -1
      return num
    })
  }, [availableExports])

  if (availableExportsLoading) return <Spinner />

  return (
    <table>
      <thead>
        <tr>
          <th>Van</th>
          <th>Tot</th>
          <th>Status</th>
          <th>Gestart op</th>
          <th>Afgerond op</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {
          sortedExports.map(availableExport => {
            return <ExportRow key={availableExport._id} availableExport={availableExport} />
          })
        }
      </tbody>
    </table>
  )
}

const dates = new Intl.DateTimeFormat('nl-BE')
const datesHours = new Intl.DateTimeFormat('nl-BE', { dateStyle: 'short', timeStyle: 'medium' })
function formatDate (fn, date) {
  try {
    return fn.format(date)
  } catch (error) {
    return ''
  }
}

const possibleStatuses = {
  'in-progress': 'Bezig',
  finished: 'Afgerond',
  failed: 'Gefaald'
}

const ExportRow = ({ availableExport }) => {
  const [downloadError, setDownloadError] = useState(null)
  const [downloadLoading, setDownloadLoading] = useState(false)

  useHandleErrors([downloadError])

  async function handleDownloadClick () {
    setDownloadLoading(true)
    setDownloadError(null)
    try {
      const result = await api.exports.downloadExport({ exportObjectId: availableExport._id })
      const blob = await result.blob()
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = url
      link.download = 'schoolteamgegevens-export.zip'
      try {
        const contentDisposition = result.headers.get('content-disposition') || ''
        const filename = contentDisposition.split(';').find(string => ~string.indexOf('filename')).trim().split('=')[1].slice(1, -1)
        if (filename) {
          link.download = filename
        }
      } catch (error) {}
      link.click()
    } catch (error) {
      console.error(error)
      setDownloadError(error)
    } finally {
      setDownloadLoading(false)
    }
  }

  return (
    <tr>
      <td>{formatDate(dates, new Date(availableExport.from))}</td>
      <td>{formatDate(dates, new Date(availableExport.to))}</td>
      <td>{possibleStatuses[availableExport.status]}</td>
      <td>{formatDate(datesHours, new Date(availableExport.startedAt))}</td>
      <td>{availableExport.status === 'finished' && formatDate(datesHours, new Date(availableExport.finishedAt))}</td>
      <td>{availableExport.status === 'finished' && (downloadLoading ? <Spinner /> : <button className="table-export-download" onClick={handleDownloadClick}>Download</button>)}</td>
    </tr>
  )
}

export default AdminTools
