/* eslint-disable no-unused-vars */
import { useState } from 'react'
import api from '../../api/api.js'
import Input from '../../components/Input/Input.js'
import Button from '../../components/Button/Button.js'
import Icon from '../../components/Icon/Icon.js'
import './Login.scss'
import history from '../../history.js'
import setLoggedIn from '../../utils/setLoggedIn.js'
import Spinner from '../../components/Spinner/Spinner.js'
import useHandleErrors from '../../hooks/useHandleErrors.js'
import useHasUnmounted from '../../hooks/useHasUnmounted.js'

const Login = () => {
  const [state, setState] = useState({
    username: '',
    password: ''
  })
  const [errors, setErrors] = useState({
    username: false,
    password: false
  })
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const hasUnmounted = useHasUnmounted(false)
  const openEnquete = true // Open enquete

  useHandleErrors([error])

  async function handleSubmit (e) {
    e.preventDefault()
    setError(null)
    if (state.username && state.password) {
      setLoading(true)
      let token
      try {
        const result = await api.authentication.login(state)
        token = result.token
        if (!result.success) {
          if (result.error === 'userNotFoundOrInvalidPassword') {
            if (!hasUnmounted.current) {
              setError(result)
              return setErrors({
                username: true,
                password: true
              })
            }
          }
        }
      } catch (error) {
        if (!hasUnmounted.current) {
          return setError(error)
        }
      } finally {
        if (!hasUnmounted.current) {
          setLoading(false)
        }
      }
      if (!hasUnmounted.current) {
        setLoggedIn(token, state.username)
        return history.push('/')
      }
    }
    if (!hasUnmounted.current) {
      setErrors({
        username: !state.username,
        password: !state.password
      })
    }
  }

  function handleChange (name) {
    return e => {
      setState({
        ...state,
        [name]: e.target.value
      })
      setErrors({
        ...errors,
        [name]: false
      })
    }
  }

  function handlePolicyClick (e) {
    e.preventDefault()
    history.push('/privacy-policy')
  }

  return (
    <div className="Login">
      <div className="left">
      { openEnquete
        ? (<div className="text">
          <h3>Update jouw schoolteamgegevens en ontvang een</h3>
          <h3 className="yellow">&nbsp;nieuwe Schoolagenda 2024-2025* voor iedereen in de lijst!</h3>
          <p>We verzamelen de gegevens van jouw schoolteam om gerichter en beter te kunnen communiceren over Bingel en over leermiddelen die in jouw school in gebruik zijn.</p>
          <p><small>* De schoolagenda’s worden verstuurd vanaf maart 2024.</small></p>
        </div>)
        : null}
        <div className="image"><img alt="agenda" src="/images/agenda.png" /></div>
      </div>
      <div className="right">
        { openEnquete
          ? (
          <>
            <img alt="logo" src="/images/logo.png" />
            <h2>Log in</h2>
            <form onSubmit={handleSubmit}>
              <Input
                label="Klantnummer"
                value={state.username}
                onChange={handleChange('username')}
                hasError={!!errors.username}
              />
              <Input
                label="Unieke code"
                value={state.password}
                onChange={handleChange('password')}
                hasError={!!errors.password}
                type="password"
              />
              <div className="submit">
                <Button disabled={loading} type="submit">
                  {loading
                    ? (
                    <Spinner width={24} height={24} stroke="var(--white)" />
                      )
                    : (
                        'Log in'
                      )}
                </Button>
              </div>
            </form>
            <p>
              Je vindt jouw klantnummer en code in een brief en mail verstuurd
              op 16 oktober.
            </p>
            <p>
              Vind je de brief of mail niet terug?
              <br />
              Neem contact op via het {' '}
              <a href="https://www.vanin.be/lager-onderwijs/contact/">
                contactformulier.
              </a>
            </p>
          </>
            )
          : (
              <div className='closedEnquete'>
                <p>
                  De schoolteamenquête voor schooljaar 2022-2023 is afgelopen.
                  De schoolagendaatjes 2023-2024 zijn uitverkocht.
                  In oktober starten we de nieuwe enquête op waarmee je voor het hele schoolteam schoolagenda’s kunt reserveren voor schooljaar 2024-2025 (de agenda’s worden dan uitgestuurd in maart 2024). Je wordt daar via brief en mail van op de hoogte gebracht.
              </p>
              </div>
            )}
        <div className="policy-button">
          <a href="/privacy-policy" onClick={handlePolicyClick}>Privacy policy</a>
        </div>
      </div>
    </div>
  )
}

export default Login
